import appConfig from "configs/app.config"
import ApiService from "./ApiService"

// export async function apiGetTerminalOptions (guid) {
//     return ApiService.fetchData({
//         url: `terminal-options/${guid}` ,
//         method: 'get'
//     })
// }

export async function apiGetTerminalOptions (guid) {
    const url = `${appConfig.apiPrefix}terminal-options/${guid}`;
    return fetch(url).then(res => res.json());
}

export async function apiGetPlayersOptions (guid) {
    const url = `${appConfig.apiPrefix}players-options/${guid}`;
    return fetch(url).then(res => res.json());
}
export async function apiCheckActivationCodeRequest(code) {
    const url = `${appConfig.apiPrefix}validate-activation-code`;
    const data = {activation_code : code};
    let formData = new FormData();
    formData.append("activation_code", code)
    return fetch(url, {method: 'POST',body: formData}).then(res => res.json());
}
