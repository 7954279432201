import React, { useEffect, useRef, useState } from "react";
import { ConfigProvider } from "components/ui";
import useDarkMode from "utils/hooks/useDarkMode";
import { themeConfig } from "configs/theme.config";
import * as uuid from 'uuid'
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { setSocket } from "store/base/socketSlice";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  EXPIRY_SECONDS_TO_HIDE,
  VALIDATION_CODE_COOKIE,
} from "constants/app.constant";
import { useCookies } from "react-cookie";

import SweetAlert2 from 'react-sweetalert2';
import apiConfig from "configs/api.config";
import { ENVS } from "constants/api.constant";
// const ENDPOINT = "https://sock.tsedbox.e-melo.fr";
// const ENDPOINT = "https://socket.terminal.osher-tsedbox.fr";
const ENDPOINT = apiConfig.endpoint;
let intervalId = null;
let UNIQUE_GUID = uuid.v4();

const Theme = (props) => {
  const intervalRef = useRef(null);
  const [cookies, setCookie, removeCookie] = useCookies([
    VALIDATION_CODE_COOKIE,
  ]);

  const {terminal : baseTerminal,companyTerminal} = useSelector((state) => state.base.terminal);

  const terminal = companyTerminal ? companyTerminal : baseTerminal;

  const theme = useSelector((state) => state.theme);
  const [isDark] = useDarkMode();
  const [swalErrorProps, setSwalErrorProps] = useState({});
  const currentTheme = {
    mode: isDark ? "dark" : "light",
    ...themeConfig,
    ...theme,
  };

  const dispatch = useDispatch();
  let socket = useSelector((state) => state.base.socket.socket);
  let CURRENT_SOCKET = null;
  
  let rand = Math.random(2);
  useEffect(() => {
    if (Object.keys(terminal).length === 0) return;

    if(baseTerminal.t_multi_assoc == 1){

      if(!companyTerminal){
        return;
      }
    }
	const currentDatetime = new Date();
	const openingDate = currentDatetime.toISOString().slice(0, 19).replace('T', ' ');
	let terminalObj = JSON.stringify({
		obj: (terminal),
		id: terminal.t_id,
		tpe: terminal.t_tpe_id,
		merchantId: terminal.t_env == ENVS.DEV ? terminal.t_merchant_id :  terminal.c_merchant_web_service,
		openingDate: openingDate
	})
	
    let params = {
      query: { sessionId: UNIQUE_GUID, type: "terminal", 
	  room: "terminals", 
	  terminal :terminalObj,
	  openingDate: openingDate },
    };
    
  if(socket){
    console.log('END CONNECTION')
    socket.disconnect();
  }
  	console.log("try socket connection",ENDPOINT, params)
    socket = socketIOClient(ENDPOINT, params);
    socket.on("connected", (data) => {
      dispatch(setSocket(socket));
      // setResponse(data);
    });

	socket.on("terminal-busy", (data) => {
		setSwalErrorProps({
			show: true,
			title: data.message,
			icon: 'error',
			showConfirmButton: true,
			allowOutsideClick: false,
			showCancelButton: false,
			confirmButtonColor: themeConfig.defaultColor,
			cancelButtonColor: '#000',
			confirmButtonText: "Essayer à nouveau",
			
			onResolve: (e) => {
				console.log("resolve",e);
				setSwalErrorProps({});
				window.location.reload();
				
			}
		})

		// socket.disconnect();
		// setResponse(data);
	  });
	  socket.on("terminal-free", (data) => {
		setSwalErrorProps({})
		// setResponse(data);
		// socket = socketIOClient(ENDPOINT, params);

	  });
	  
	
  // }, [terminal,companyTerminal]);
}, [terminal]);


  return (
    <>
      <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>

	  <SweetAlert2 {...swalErrorProps}  didClose={() => {
                    setSwalErrorProps({})
                }} 
                />
    </>
  );
};

export default Theme;
