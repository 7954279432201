import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter  } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import './locales'

import TbModalResendCode from 'components/shared/modals/ModalResendCode'

import { useCookies } from 'react-cookie';
import { VALIDATION_CODE_COOKIE } from 'constants/app.constant'
// import 'swiper/css';
const environment = process.env.NODE_ENV

// if (environment !== 'production' && appConfig.enableMock) {
// 	mockServer({ environment })
// }

mockServer({ environment })

function App() {
	const [cookies, setCookie, removeCookie] = useCookies([VALIDATION_CODE_COOKIE]);
    
	// const controlRedirect = () => {
	// 	const navigate = useNavigate();
	// 	const location = useLocation();
	// 	const path = location.pathname.substring(
	// 		location.pathname.lastIndexOf("/") + 1
	// 	);
	// 	  console.log("tab path change",path);
	// 	//   navigate(`/app/live-assistant/`);
	// 	};
	// const count = useGlobalIntervalTimer(1000, controlRedirect);
	const [modalOpened, setModalOpened] = useState(false)
	useEffect(() => {
		// setTimeout(() => {
		// 	setModalOpened(true)
		// }, 3000);
		if(!cookies.code){
			setModalOpened(true)
		  }
	}, [cookies])

	
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter history={history}>
					<Theme>
						<Layout />
						
						{/* {modalOpened && <TbModalResendCode isOpen={modalOpened} setIsOpen={setModalOpened} />} */}
					</Theme>
				</BrowserRouter >
			</PersistGate>
		</Provider>
	)
}

export default App
